<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st_document_template_create_form"
        >
            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    :ref="fields.name.name"
                    :name="fields.name.name"
                    v-model="model[fields.name.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.description.label }} *
                </label>
                <b-form-textarea
                    :ref="fields.description.name"
                    :name="fields.description.name"
                    v-model="model[fields.description.name]"
                />
            </div>

            <div class="pt-6">
                <slot name="buttons" :submit="submit" :cancel="cancel"/>
            </div>
        </form>
    </div>
</template>

<script>
import { FormSchema } from '@/shared/form/form-schema';
import { DocumentTemplateModel } from '@/modules/document-template/document-template-model';
import { createFormValidation } from '@/shared/utils/create-form-validation';

const {fields} = DocumentTemplateModel;

const formSchema = new FormSchema([
    fields.name,
    fields.description,
]);

export default {
    name: 'DocumentTemplateCreateForm',
    props: {
        item: {
            type: Object,
            default: () => ({ }),
        }
    },
    data() {
        return {
            model: formSchema.initialValues(this.item),
            fields,
            rules: formSchema.rules(),
        };
    },
    mounted() {
        this.fv = createFormValidation('st_document_template_create_form', this.rules);
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                this.$emit('submit', cast);
            }
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

